html,
body {
    background-color: #EEF0FD;
    padding: 0;
    margin: 0;
    font-family: 'Work Sans', sans-serif;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

body {
    padding: 0 0 200px;
}

a {
    color: rgb(196, 69, 54);
    /*text-decoration: none;*/
}

a.use-icon::after {
    content: url('images/openlink.svg');
    margin-left: 8px;
    vertical-align: middle;
}

* {
    box-sizing: border-box;
}

.szh-menu__item--active {
    color: #fff;
    background-color: #C44536;
}

a.szh-menu__item {
    color: #0f1621;
    text-decoration: none;
}

sup {
    font-size: 70%;
    margin-right: 1px;
}

@font-face {
    font-family: 'Work Sans';
    src: url(./fonts/WorkSans-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
