.animated-check {
    height: 25px;
    width: 25px;
}

.animated-check path {
    fill: none;
    stroke: #fff;
    stroke-width: 4;
    stroke-dasharray: 23;
    stroke-dashoffset: 23;
    animation: draw 0.3s linear forwards;
    animation-delay: 0.6s;
    stroke-linecap: round;
    stroke-linejoin: round;
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}
